var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"pl-4 pr-0 pt-2 d-flex"},[_c('div',{staticClass:"pr-0 pt-4 left_filter"},[_c('setting-menu')],1),_c('div',{staticClass:"div_body pl-3 pr-0 mx-0"},[_c('v-card',{staticClass:"background_color_transparent",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pt-7"},[_c('Btn',{attrs:{"to":{
                        name: 'eav.entity_type.property.edit',
                        params: {
                            id: 0,
                            entity_type_slug: _vm.slug
                        }
                    }}},[_vm._v(" "+_vm._s(_vm.$t('create'))+" ")]),_c('Btn',{on:{"click":_vm.fetchItems}},[_vm._v(" "+_vm._s(_vm.$t('refresh'))+" ")]),_c('ResizableTable',{attrs:{"columns":_vm.headers,"rows":_vm.items},scopedSlots:_vm._u([{key:"name",fn:function({ item }){return [_c('div',{class:item.deleted ? 'red--text' : ''},[_c('router-link',{staticClass:"cursor-pointer font_weight_600",attrs:{"to":{name: 'eav.entity_type.property.edit', params: {
                                    entity_type_slug: _vm.slug,
                                    id: item.id
                                }}}},[_vm._v(" "+_vm._s(item.name)+" ")]),(!item.deleted)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteitem(item.id)}}},[_vm._v("mdi-delete-outline")]):_vm._e()],1)]}}])})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.totalCountries > _vm.perPage),expression:"totalCountries > perPage"}],staticClass:"pagination_table",attrs:{"total-visible":_vm.totalVisiblePag,"length":_vm.pageCount,"disabled":_vm.loading},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }